'use strict'

const cart_container = document.getElementById('cart-container')

cart_container && cart_container.addEventListener('click', event => {
	if (event.target.nodeName === 'A' && event.target.hasAttribute('data-role') && event.target.getAttribute('data-role') === 'create-an-account') {
		event.preventDefault();
		window.sessionStorage.setItem('url_after_creating_account', location.href);
		location.href = event.target.getAttribute('href');
	}
})

const nav_after_completion = document.querySelector('nav.nav-after-completion');

if (nav_after_completion) {
	const url = window.sessionStorage.getItem('url_after_creating_account');

	if (url) {
		const anchor = document.createElement('a')
		anchor.textContent = 'Continue shopping'
		nav_after_completion.appendChild(anchor)

		anchor.addEventListener('click', event => {
			event.preventDefault();

			window.sessionStorage.removeItem('url_after_creating_account')
			location.href = url;
		})
	}
}